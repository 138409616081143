<script setup>
    import { useRegistrations } from '@/stores/registrations';

    const store = useRegistrations();

    const props = defineProps({
        attribute: String,
        label: String, 
        maxlength: String,
        placeholder: String, 
        modelValue: String, 
    });
</script>

<template>
    <label v-if="label" class="font-bold text-xl pb-1" :class="store.isRequired(attribute)">{{ label }}</label>
    <input 
        class="w-full rounded-md border-0 pl-4 py-2.5 text-xl text-blue shadow-sm ring-1 ring-inset ring-blue focus:ring-2 focus:ring-inset focus:ring-cram_pink" 
        type="text" 
        :maxlength="maxlength"
        :value="modelValue"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
        />
</template>